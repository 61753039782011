import { graphql, useStaticQuery, Link } from "gatsby"
import React, { useState } from "react"
import { motion, useCycle } from "framer-motion"
import Img from "gatsby-image"
import styled from "styled-components"
import Animation from "../shared/animation.js"

function Mobilemenu({ state, links }) {
  const variants = {
    open: { height: "auto" },
    closed: { height: "0" },
  }

  return (
    <motion.div
      className="md:hidden fixed flex justify-between mx-4   bg-offwhite border-t  border-white text-black items-center overflow-hidden font-body "
      variants={variants}
      animate={state ? "open" : "closed"}
    >
      {/*  spacer div 1 for space-between to function in header */}
      <div className="flex mx-4  items-center w-full ">
        {links.map(link => (
          <Link
            // onMouseEnter={() => expandWriting(false)}
            className="text-black font-light   text-sm mr-6 no-underline hover:text-blue"
            key={link.title}
            to={link.route}
          >
            {link.title}
          </Link>
        ))}
      </div>
      <div className=" w-1/2"></div>
    </motion.div>
  )
}

export default Mobilemenu
