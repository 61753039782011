import React, { Suspense, useRef, useState, useEffect, useMemo } from "react"
import * as THREE from "three"
import { SVGLoader as loader } from "three/examples/jsm/loaders/SVGLoader"
import { Canvas, useFrame, useThree, useLoader } from "react-three-fiber"
import url from "../../../content/assets/book.svg"
import { flatten, flattenDeep } from "lodash"

const Scene = React.memo(({ url }) => {
  const { viewport } = useThree()
  const mesh2 = useRef()
  const mesh = useRef()
  const group = useRef()

  //latest method of loading svg
  const svgs = useLoader(loader, url)
  const shapes = flatten(
    useMemo(() =>
      // svgs.map(({ paths }) =>
      svgs.paths.map(
        (path, index) =>
          path.toShapes(true).map(shape => ({
            shape,
            color: path.color,
            fillOpacity: path.userData.style.fillOpacity,
            index,
          }))
        //   )
      )
    ),
    [svgs]
  )

  useFrame(
    () => (group.current.rotation.x = group.current.rotation.y += 0.01)
    // (group.current.rotation.y -= 0.5)
  )

  //   console.log("mapped shape" + "" + JSON.stringify(shapes))
  //   console.log("mappssed shape" + "" + [shapes[1].shape])
  const options = {
    steps: 5,
    depth: 10,
    bevelEnabled: true,
    bevelThickness: 40,
    bevelSize: 3,
    bevelOffset: 0,
    bevelSegments: 4,
  }

  return (
    <>
      <pointLight position={[10, 10, 10]} />
      <group scale={[1, -1, 1]} ref={group}>
        {shapes.map(({ shape }) => (
          <mesh ref={mesh2} scale={[0.005, 0.005, 0.005]}>
            <extrudeGeometry attach="geometry" args={[shape, options]} />
            <meshPhongMaterial
              attach="material"
              wireframe="true"
              color="white"
            />
          </mesh>
        ))}
      </group>
      {/* <mesh ref={mesh} scale={[0.1, 0.1, 0.1]}>
        <shapeGeometry attach="geometry" args={[heart]} />
        <meshBasicMaterial attach="material" color="black" />
      </mesh> */}

      {/* <Box /> */}
      {/* <Extrusion
        curveSegments={20}
        steps={2}
        depth={3}
        bevelEnabled={false}
        bevelThickness={4}
        bevelSize={1}
        bevelOffset={0}
        bevelSegments={1}
        paths={shapes}
      /> */}
    </>
  )
})
// const svgResource = new Promise(resolve =>
//   new loader().load(url, data => {
//     flatten(resolve(data.paths.map((path, index) => ({ path, index }))))
//   })
// )

function Extrusion({ shapes, ...props }) {
  const mesh = useRef()
  //   useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.01))
  console.log(JSON.stringify(shapes))
  //   const shape = useMemo(() => {
  //   const shape = new THREE.Shape()
  //     shape.moveTo(0, 0)
  //     paths.forEach(path => console.log(...path), shape.lineTo(...path))
  //     console.log("FACELESS?" + JSON.stringify(shape))
  //     // shape.autoClose = true
  //     return shape
  //   }, [paths, props])
  //   console.log([shape])
  return (
    <group>
      <mesh ref={mesh} scale={[1, 1, 1]}>
        <meshBasicMaterial attach="material" color="black" />
        <shapeGeometry attach="geometry" args={[shapes]} />
      </mesh>
    </group>
  )
}

function Box(props) {
  // This reference will give us direct access to the mesh
  const mesh = useRef()
  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)
  // Rotate mesh every frame, this is outside of React without overhead
  useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.01))

  return (
    <mesh
      {...props}
      ref={mesh}
      scale={active ? [1.5, 1.5, 1.5] : [1, 1, 1]}
      onClick={e => setActive(!active)}
      onPointerOver={e => setHover(true)}
      onPointerOut={e => setHover(false)}
    >
      <boxBufferGeometry attach="geometry" args={[0.5, 1, 1]} />
      <meshPhongMaterial
        attach="material"
        color={hovered ? "hotpink" : "orange"}
      />
    </mesh>
  )
}

function Animation(props) {
  //   const [shapes, setShapes] = useState([])
  //   useEffect(() => void svgResource.then(setShapes), [])
  //   const [paths, setPaths] = useState([])
  //   useEffect(() => void svgResource.then(setPaths), [])

  return (
    <div className="h-24">
      <Canvas>
        <Suspense fallback={null}>
          <Scene url={url} />
        </Suspense>
      </Canvas>
    </div>
  )
}
export default Animation
