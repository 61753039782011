import PropTypes from "prop-types"
import React from "react"
import Header from "./header"
import Footer from "./footer"

function Layout({ children }) {
  const today = new Date()
  return (
    <div className="flex flex-col bg-offwhite min-h-screen font-body ">
      <Header />
      <main className="flex flex-col my-auto md:w-1/2 w-full md:m-auto  justify-center p-8 md:p-0">
        {children}
      </main>
      <footer className="bottom-0 z-50">
        <nav className="flex justify-between text-sm md:p-0 md:py-4 p-4 mx-auto">
          <div className="hidden md:flex w-1/2"></div>
          <div className="flex flex-row w-full justify-between items-center">
            <p className="text-xs text-black">
              Created by{` `}
              <a
                className="font-bold no-underline"
                href="https://www.johndore.dev"
                target="_blank"
              >
                John Dore
              </a>
            </p>

            <p className="font-light text-xs no-underline">
              © A.Wilding {today.getFullYear()}
            </p>
          </div>
          <div className="hidden md:flex w-1/2"></div>
        </nav>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
