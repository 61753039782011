import { graphql, useStaticQuery, Link } from "gatsby"
import React, { useState } from "react"
import { motion, useCycle } from "framer-motion"
import Submenu from "./navigation/submenu.js"
import Mobilemenu from "./navigation/mobilemenu.js"
import styled from "styled-components"
import Animation from "./shared/animation.js"
import HeadRoom from "react-headroom"

function Header() {
  const [isMobile, toggleMobile] = useState(false)
  const [writing, expandWriting] = useState(false)
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const variants = {
    open: { height: "auto" },
    closed: { height: 0 },
  }

  const wrapperStyle = {
    position: "fixed",
    display: "flex",
    width: "full",
    left: 0,
    top: 0,
    margin: "auto",
    height: "3rem",
    backgroundColor: "#fffdf9",
  }

  const routes = [
    {
      route: `/reviews`,
      title: `Reviews`,
    },
    // {
    //   route: `/articles`,
    //   title: `Articles`,
    // },
    {
      route: `/about`,
      title: `About`,
    },
  ]

  return (
    <header className="p-0 z-50 outline-none">
      {/* responsive react-headroom nav bar */}
      <HeadRoom wrapperStyle={{ marginTop: "0" }}>
        <div className="flex left-0 top-0 items-center py-4 md:justify-between h-18 w-full mx-auto bg-offwhite">
          {/*  spacer div #1 */}
          <div className="w-1/2 hidden md:flex text-center"></div>
          <div className="flex flex-row justify-start md:justify-between items-center md:w-full">
            <Link
              className="items-center text-black md:mx-0 mx-8  no-underline hover:text-blue"
              to="/"
            >
              <span className="text-xl tracking-wide">
                {site.siteMetadata.title}
              </span>
            </Link>
            {/* mobile layout menu button */}
            <button
              className="block md:hidden border border-white flex   rounded"
              onClick={() => toggleMobile(!isMobile)}
            >
              <svg
                className="fill-current h-4 w-4"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
            <nav className="text-black  md:flex  md:w-auto hidden">
              {routes.map(link => (
                <Link
                  onMouseEnter={() => expandWriting(false)}
                  className="text-black font-light inline-block h-4 md:inline-block text-base md:ml-6 no-underline hover:text-blue"
                  key={link.title}
                  to={link.route}
                >
                  {link.title}
                </Link>
              ))}
              <div className="h-4 font-body text-base text-left md:ml-6 align-center">
                <a
                  href="mailto:Info.Wilding@gmail.com"
                  className="focus:outline-none md:inline-block align-center font-light m-0 p-0 no-underline hover:text-blue"
                >
                  Contact
                </a>
              </div>
            </nav>

            {/*  spacer div for space-between to function in header */}
          </div>
          <div className="w-1/2 hidden md:flex text-center"></div>
        </div>
        <Mobilemenu links={routes} state={isMobile}></Mobilemenu>
        {/* <Submenu state={writing}></Submenu> */}
      </HeadRoom>
    </header>
  )
}

export default Header

// <a href="mailto:Info.Wilding@gmail.com"></a>
